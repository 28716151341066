import React, { Component } from 'react';
import { Button, Icon, Input } from 'semantic-ui-react'

class SearchContainer extends Component {
    state = { pagina: undefined }

    componentDidMount = () => {
        var input = document.getElementById("find");

        input.addEventListener("keyup", (event) => {
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                this.GoSearch();
            }
        })
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ value })
    }

    GoSearch = () => {
        const { value } = this.state
        if (value)
            window.location.href = '/zoek/' + value
    }

    render = () => {
        const { value } = this.state
        return(
        <div id='zoek'>
            <Input id='find' onChange={this.handleSearchChange} value={value} />
            <Button compact animated inverted onClick={() => this.GoSearch()}>
                <Button.Content visible>Zoeken</Button.Content>
                <Button.Content hidden>
                    <Icon name='search' />
                </Button.Content>
            </Button>  
            
        </div>
        
        )
    }
}

export default SearchContainer