import React, { Component } from 'react';
import { Merk } from '../../dumb/merk';
import { Dimmer, Header, List, Image, Segment } from 'semantic-ui-react';
import { LandLabel } from '../../dumb/landLabel';

class MerkenContainer extends Component {
    state = { imagedimmer: false, src: '' }

    handleShowImage = (src) => { 
        this.setState({ imagedimmer: true, src: src }) 
    }
    handleHideImage = () => this.setState({ imagedimmer: false })
  
    getMerk = (number) => {
        if (number !== 'undefined') {
            fetch('/api/merken/' + number)
            .then(response => response.json())
            .then(data => { 
                this.setState({ merk: data }, () => {
                    data.forEach(car => {
                        this.getComments(car.ID)
                    });
                })
            })
            .catch(err => {
              console.log(err)
            })
        }
    }

    getMerkNaam = (name) => {
        if (name !== 'undefined') {
            fetch('/api/merken/naam/' + name)
            .then(response => response.json())
            .then(data => {
                this.setState({ merk: data }, () => {
                    data.forEach(car => {
                        this.getComments(car.ID)
                    });
                })
            })
            .catch(err => {
              console.log(err)
            })    
        }
    }

    getComments = (merkNummer) => {
        const { merk } = this.state
        fetch('/api/commentaar/' + merkNummer)
        .then(response => response.json())
        .then(data => {
            let merkComments = merk
            merkComments.forEach(car => {
                var newComments = []
                data.forEach((comment) => {
                    if (car.ID === comment.Merk)
                    {
                        newComments.push(comment)
                        car.comments = newComments
                    }
                })
            })
            this.setState({merk: merkComments})
        })
        .catch(err => {
          console.log(err)
        })

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { merkNaam, merkNummer } = newProps
        if (merkNaam) {
            this.getMerkNaam(merkNaam)
        }
        else
        {
            this.getMerk(merkNummer);
        }

    }

    componentDidMount = () => {
        const { merkNaam, merkNummer } = this.props
        if (merkNaam) {
            this.getMerkNaam(merkNaam)
        }
        else
        {
            this.getMerk(merkNummer);
        }
    }

    render = () => {
        const { merk, imagedimmer, src } = this.state
        return (
            <>
            {merk ? 
                merk.length === 1 ?
                    // er is maar 1 merk, dus laat het merk zien
                        <>
                         <Merk handleshowimage={(src) => this.handleShowImage(src)} Car={merk[0]} />
                         <Dimmer page verticalAlign='top' active={imagedimmer} onClickOutside={() => this.handleHideImage()}>
                            <Header as='h1' inverted>Showing image of {merk[0].merk}</Header>
                            <Image style={{cursor: 'pointer'}} verticalAlign='middle' as='a' onClick={() => this.handleHideImage()} fluid src={src} />
                        </Dimmer>
                        </>
                    :
                    // er zijn meerdere merken gevonden met dezelfde naam, geef de gebruiker een selectie om door te klikken
                    <List selection verticalAlign='middle'>
                    {merk.map((car, index) => {
                        return(<List.Item as='a' href={'/merk/' + car.merk + '/' + car.ID} key={`item_${index}`} style={{height: '45px'}}>
                            <List.Content>
                                <List.Header>{car.merk}</List.Header>
                                <List.Description>
                                    <span>land(en): {car.countries.map((country, index) => {
                                    return(<LandLabel key={index} Land={country} />)}
                                    )}</span>
                                    <span>{' '}van: <b>{car.van}</b></span>
                                    <span>{' '}tot: <b>{car.tot}</b></span>
                                </List.Description>
                            </List.Content>
                            </List.Item>
                        )
                    
                    })}
                    </List>
            :
                //Pagina is nog aan het laden, de gegevens zijn nog niet binnen van de API
                <Segment loading></Segment>
            }
            
            </>
        )
    }
}

export default MerkenContainer