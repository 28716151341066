import React from 'react'
import { Comment, Header } from 'semantic-ui-react'
import Mailto from '../../../helpers/MailTo'
import moment from 'moment'
import 'moment/locale/nl'
import AddCommentContainer from '../../smart/addCommentContainer';
moment.locale('nl')

function replaceText(text) {
    let newText = text.split ('\\r\\n').map ((item, i) => <p key={i}>{item}</p>);
    return newText
}

const Comments = ({comments, model, modelId}) => (
    <Comment.Group>
      <Header as='h3' dividing>
        Opmerkingen
      </Header>
        {comments ? comments.map((comment,index) => {
            return(<Comment key={index}>
                <Comment.Avatar src='/data/images/usercomment.png' />
                <Comment.Content>
                <Comment.Author>
                    <Mailto obfuscate={true} email={comment.Mail}>{comment.Naam}</Mailto>
                </Comment.Author>
                <Comment.Metadata>
                    {moment(comment.Datum).startOf('day').fromNow()}
                </Comment.Metadata>
                <Comment.Text>{replaceText(comment.Commentaar)}</Comment.Text>
                <Comment.Actions>
                    <Comment.Action></Comment.Action>
                </Comment.Actions>
                </Comment.Content>
            </Comment>)
            }) : null
        }

        <AddCommentContainer modelId={modelId} model={model} />
   
    </Comment.Group>
  )

  export default Comments