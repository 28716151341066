import React, { Component } from 'react'
import PaginaContainer from '../../components/smart/paginaContainer';
import CategorieContainer from '../../components/smart/categorieContainer';
import MerkenContainer from '../../components/smart/merkenContainer';
import SearchPageContainer from '../../components/smart/searchPageContainer';
import LandenContainer from '../../components/smart/landenContainer';
import ContactContainer from '../../components/smart/contactContainer';
import FooterSocial from '../../components/dumb/footer';

import { Segment } from 'semantic-ui-react'
import AdminContainer from '../../components/smart/adminContainer';

const isNumber = require('is-number');
export class Main extends Component {
    
    componentDidMount () {
        window.googleTranslateElementInit = this.googleTranslateElementInit
    }

    googleTranslateElementInit = () => {
        /* eslint-disable no-new */
        new window.google.translate.TranslateElement({pageLanguage: 'nl', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE}, 'zoek')
    }

    render () {
        const prop = this.props
        return (
            
            <Segment.Group>
            <Segment.Group horizontal>
                {prop.comp === 'pagina' ? <PaginaContainer paginaNummer={prop.match.params.id} {...prop} /> : null}

                {prop.comp === 'categorie' ? 
                    !isNumber(prop.match.params.id) ? 
                        <CategorieContainer categorieNaam={prop.match.params.id} {...prop} />
                    :   <CategorieContainer categorieNummer={prop.match.params.id} {...prop} /> 
                : null}

                {prop.comp === 'merk' ? 

                isNumber(prop.match.params.nummer) ? <MerkenContainer merkNummer={prop.match.params.nummer} {...prop} /> :
                        isNumber(prop.match.params.id) ? <MerkenContainer merkNummer={prop.match.params.id} {...prop} />
                        : prop.match.params.id !== 'undefined' ? <MerkenContainer merkNaam={prop.match.params.id} {...prop} /> : <SearchPageContainer {...prop} />
                : null}
                {prop.comp === 'search' ? <SearchPageContainer poss={false} search={prop.match.params.term} {...prop} /> : null}

                {prop.comp === 'landen' ? <LandenContainer {...prop} /> : null}

                {prop.comp === 'contact' ? <ContactContainer {...prop} /> : null}

                {prop.comp === 'admin' ? <AdminContainer {...prop} /> : null}

            </Segment.Group>
            <FooterSocial />
            </Segment.Group>
        )
    }            
}

export default Main