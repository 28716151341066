import React from 'react'
import { Image } from 'semantic-ui-react'

function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

const ImageGroup = (props) => (
    <Image.Group size='tiny'>
    {props.images ? props.images.map((image,index) => {
        let src = '/auto/images/' + pad(props.carid,5) + '/' + image + '.jpg'

        if (image === 'lo' || image === 'logo')
        {
            src = '/auto/images/' + pad(props.carid,5) + '/' + image + '.gif'
        }
        return (
            <Image key={index} style={{cursor: 'pointer'}} as='a' onClick={() => {
                props.handleShowImage(src)
            }} src={src} />
        )
     }) : null }
    </Image.Group>
)

export default ImageGroup