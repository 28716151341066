
import React from 'react'
import { Label } from 'semantic-ui-react'

export const LandLabel = (...props) => {
    if (props[0].Land) {
        return(<Label as='a' href={'/zoek/landen/' + props[0].Land} title={`Zoek naar ${props[0].Land}`} circular basic color='black'>{props[0].Land}</Label>)
    }
    else {
        return (<></>)
    }
}