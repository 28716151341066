import React from 'react'
import { Button, Segment, Label } from 'semantic-ui-react'

let date =  new Date().getFullYear()

const FooterSocial = () => (
  <Segment padded inverted color='black' style={{ marginTop: 100 }}>
  
    <Label color='black'>Copyright © 1997-{date} Jan Clevering</Label>
    <Button.Group floated='right' size='tiny'>
      <Button as='a' circular color='facebook' icon='facebook' href='https://www.facebook.com/dAuto.nl/' />
      <Button as='a' circular color='twitter' icon='twitter' href='https://twitter.com/dAuto_nl' />
      <Button as='a' circular color='orange' icon='feed' href='/feeds' />
      <Button as='a' circular color='google plus' icon='mail' href='/contact' />
    </Button.Group>
  </Segment>

)

export default FooterSocial