import React, { Component } from 'react';
import { Item } from 'semantic-ui-react'
import { LandLabel } from '../../dumb/landLabel';

class LandenContainer extends Component {
    state = { pagina: undefined }

    componentDidMount = () => {
        this.GetLanden()
    }

    GetLanden = () => {
        fetch('/api/landen')
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else {
                return null
            }
        })
        .then(data => {
            data.sort(function(a, b) {
                var textA = a.land.toUpperCase();
                var textB = b.land.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        
            this.setState({data: data}, () => {

            })
        })
        .catch(err => {
            console.log(err)
        })

    }

    render = () => {
        const { data } = this.state
    
        if (data) {
            return( 
                <Item.Group relaxed>
                    {data.map((land, index) => {
                        return (<Item as='a' href={'/zoek/landen/' + land.landcode}  key={index}>
                            <Item.Content verticalAlign='middle'>
                                {land.land} <LandLabel notA Land={land.landcode} />
                            </Item.Content>
                        </Item>)
                    })}
                </Item.Group> 
            )
        }
        else
            return null
    }
}

export default LandenContainer