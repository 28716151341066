import React, { Component } from 'react';
import { Button, Checkbox, Form, Input, Message, Segment } from 'semantic-ui-react'
import 'jodit'
import 'jodit/build/jodit.min.css'
import JoditEditor from 'jodit-react'

class NewForm extends Component { 
    state = { model: {}, done: false, error: false }

    jodit;
    setRef = jodit => this.jodit = jodit

    config = {
        "readonly": false,
        "removeButtons": ['hr', 'underline', 'strikethrough', 'image', 'file', 'video', 'fullsize', 'selectall', 'print', 'about'],
        "defaultMode": '3',
        "showCharsCounter": false,
        "showWordsCounter": false,
        "showXPathInStatusbar": false,
        "disablePlugins": "bold"
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { model } = newProps
        if (model !== this.props.model) {
            this.renderModel()
        }
    }

    componentWillMount = () => {
        const { model } = this.props
        this.setState({model})
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    changeModel = (e, {id, value}) => {
        let { model } = this.state
        e.preventDefault();
        
        model[id] = value
        this.setState({model})
    }
        
    changeCheckbox = (e, {id, checked}) => {
        let { model } = this.state
        
        model[id] = checked
        this.setState({model})
    }

    changeHistory = (value) => {
        let { model } = this.state
        model['geschiedenis'] = value
        this.setState({model})
    }

    renderModel = () => {
        const {model} = this.props
        let screen = []
        for (var key in model) {
            if (model.hasOwnProperty(key)) {
                if (key !== 'id') {
                    if (key.toLowerCase() === 'geschiedenis') {
                        screen.push(<Form.Field key={key} ><label>{this.capitalize(key)}:</label><JoditEditor style={{ minHeight: 100, width: 1010 }} editorRef={this.jodit} config={this.config} id={key} onChange={this.changeHistory}/></Form.Field>)
                    }
                    else if (typeof(model[key]) === 'boolean') {
                        screen.push(<Form.Field key={key} ><label>{this.capitalize(key)}:</label><Checkbox onChange={this.changeCheckbox} id={key} /></Form.Field>)
                    }
                    else if (typeof(model[key]) === 'string') {
                        screen.push(<Form.Field key={key} ><label>{this.capitalize(key)}:</label><Input onChange={this.changeModel} id={key} placeholder={model[key]} /></Form.Field>)
                    }
                    else if (typeof(model[key]) === 'number') {
                        screen.push(<Form.Field key={key} ><label>{this.capitalize(key)}:</label><Input type={typeof(model[key])} onChange={this.changeModel} id={key} placeholder={model[key]} /></Form.Field>)
                    }
                }
            }
        }
        return screen || null
    }

    save = () => {
        const { model } = this.state
        fetch('/api/merken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
        
            body: JSON.stringify(model)
          })
          .then((response) => {
            if (response.status === 201)
            {
              this.setState({done: true, error: false })
            }
            else {
                this.setState({done: false, error: true})
            }
        })
        .catch(err => {
            this.setState({done: false, error: true})
        })

    }

    render = () => {
        const { done, error } = this.state
        return (
            <Segment>
            {!done ? 
                <Form error={error}>
                <Message
                    error
                    header='Deze actie is niet toegestaan'
                    content='Je kunt deze wijziging niet opslaan.'
                />
                {this.renderModel()}
                <Message
                    error
                    header='Deze actie is niet toegestaan'
                    content='Je kunt deze wijziging niet opslaan.'
                />
                <Button type='submit' onClick={() => this.save()}>Save</Button></Form>
            :
                <h2>Je hebt het opgeslagen</h2>
            }
            </Segment>
        )
    }
}

export default NewForm