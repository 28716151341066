import _ from 'lodash'
import React from 'react';
import { Redirect as RRRedirect } from 'react-router-dom'
import { extract, stringify, parse } from 'query-string'

export const rawMarkup = (text, type) => {
  if (text) {
    text = text.replace(new RegExp('<p>', 'g'), '')
    text = text.replace(new RegExp('</p>', 'g'), <br />)
    // specific for dAuto.nl:

    text = text.replace(/_x000d_\\n/g, '')
    text = text.replace(/\\r\\n/g , '')
    text = text.replace(/\\n/g , '<br />')

    text = text.replace(/\[/g, '<a href="')
    text = text.replace(/\|int\|/g, '">')
    text = text.replace(/\|\|/g, '">')
    text = text.replace(/\]/g, '</a>')
    text = text.replace(/\|ext\|/g, '" target="_blank class="external>')
    
    if (type === 'merk') {
      text = SpecificMerk(text)
    }
    else if (type === 'categorie') {
      text = SpecificCategorie(text)
    }
    else
    {
      // do default what worked before
      text = text.replace(/cat\|/g, '"/begin/categorie.asp?cat="')
      text = text.replace(/page\|/g, "/begin/pagina.asp?page=")
    }


    text = text.replace(/merk\|/g, "/merk/")
    text = text.replace(/http:\/\/www\.dauto\.nl/g, "")
    text = text.replace(/http:\/\/www\.deautogids\.nl/g, "")
  }
  return { __html: text }
}

const SpecificMerk = (text) => {
  text = text.replace(/cat\|/g, '"/begin/categorie.asp?cat="')
  text = text.replace(/page\|/g, "/begin/categorie.asp?cat=")
  return text
}

const SpecificCategorie = (text) => {
  text = text.replace(/cat\|/g, '"/begin/categorie.asp?cat="')
  text = text.replace(/page\|/g, "/begin/categorie.asp?cat=")
  return text
}

const mergeQueryStrings = (...args) => {
  const queryString = stringify(_.transform(args, (result, value) => {
    _.assign(result, _.isString(value) ? parse(value) : value)
  }, {}))
  return queryString ? '?' + queryString : ''
}

export const Redirect = ({ preserveQueryString, ...props }) => {
  if (_.isUndefined(preserveQueryString)) {
    preserveQueryString = _.has(props, 'from')
  }
  if (!preserveQueryString || !window.location.search) {
    return <RRRedirect {...props} />
  }
  const { to, ...rest } = props
  const toSearch = _.isString(to) ? extract(to) : _.get(to, 'search', '')
  const search = mergeQueryStrings(window.location.search, toSearch)
  const nextLocation = _.isString(to)
    ? { pathname: to.split('?')[0], search }
    : { ...to, search }
  return <RRRedirect to={nextLocation} {...rest} />
}