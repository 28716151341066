import React from 'react'
import _ from 'lodash'
import { Input, Message } from 'semantic-ui-react'
import { MyVirtualList } from '../../dumb/virtual';
import { LandLabel } from '../../dumb/landLabel'

export default class SearchPageContainer extends React.Component {
    state = { selectValue : '', isLoading: false }
    
    componentWillMount() {
        this.resetComponent()
    }

    componentDidMount = () => {
        if (!this.props.match.params.zoekvorm) {
            this.getAlfabetList()
        }
        else
        {
            this.setState({Land: this.props.match.params.land}, () => {
                this.getLandenList(this.props.match.params.land)
            })
        }
    }

    resetComponent = () => this.setState({ isLoading: false, results: [], value: '', Land: '' })

    getLandenList = (term) => {
        fetch('/api/merken/land/' + term)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else {
                return null
            }
        })
        .then(data => {
            data.sort(function(a, b) {
                var textA = a.firstLetter.toUpperCase();
                var textB = b.firstLetter.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            this.setState({data: data}, () => {
                if (this.props.search) {
                    const re = new RegExp(_.escapeRegExp(this.props.search), 'i')
                    const isMatch = result => re.test(result.label) || re.test(result.search)
              
                    this.setState({
                      value: this.props.search,
                      isLoading: false,
                      results: _.filter(data, isMatch),
                    })
                  }
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    getAlfabetList = () => {
        fetch('/api/merken/alfabet')
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else {
                return null
            }
        })
        .then(data => {
            data.sort(function(a, b) {
                var textA = a.firstLetter.toUpperCase();
                var textB = b.firstLetter.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            this.setState({data: data}, () => {
                if (this.props.search) {
                    const re = new RegExp(_.escapeRegExp(this.props.search), 'i')
                    const isMatch = result => re.test(result.label) || re.test(result.search)
              
                    this.setState({
                      value: this.props.search,
                      isLoading: false,
                      results: _.filter(data, isMatch),
                    })
                  }
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    updateValue = (newValue) => {
        this.setState({
            selectValue: newValue
        });
    }

    handleSearchChange = (e, { value }) => {
        const { data } = this.state
        
        this.setState({ value, isLoading: true})
        
        if (value.length > 0) {
            setTimeout(() => {
                const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
                const isMatch = result => re.test(result.label) || re.test(result.search)
          
                this.setState({
                  isLoading: false,
                  results: _.filter(data, isMatch),
                })
              }, 500)
        }
        else
        {
            return this.resetComponent()
        }
    }

    handleResultSelect = (e, { result }) => this.setState({ value: result.label })

    render() {
        const {data, isLoading, results, value, Land} = this.state
        const {zoekvorm} = this.props.match.params
        return (
        <div>
            <h2>Merken {zoekvorm ? 'voor ' + zoekvorm : 'lijst'} {Land ? <LandLabel Land={Land} /> : null}</h2>

            {data ? data.length > 0 ? 
            <>
                <Input
                    loading={isLoading}
                    onChange={this.handleSearchChange}
                    value={value}
                    id='searchTextBox'
                />
                {
                    results.length === 0 && value && this.props.search ? <Message info>Er zijn geen merken met deze waarde gevonden</Message> : null
                }
                <MyVirtualList
                    width='100%'
                    height={600}
                    items={results.length > 0 || value ? results : data}
                    poss={this.props.poss}
                    itemHeight={55}
                    itemBuffer={15}
                />
            </>
            : null: null
            }
        </div>
        )
    }

    handleSorter = (selected) => {
        
    }
}