import React from 'react';
import { Route, Link } from 'react-router-dom';

const isNumber = require('is-number');
const Breadcrumbs = (props) => (
    <Route path='*' render={props => {
        let parts = []
        let place = ''
        if (props.location.pathname)
        {
            parts = props.location.pathname.split("/")
           
            place = parts[parts.length-1]
        }
        if (props.pathname) {
            parts = props.pathname.split("/")
            
            place = parts[parts.length-1]
        }
        parts = parts.slice(1, parts.length-1)
        if (parts.length === 0) { return null }

        // remove the webarchive directives from the breadcrumb
        // for example http://localhost:5001/web/20200107155656/http://localhost:5001/merk/Erfiag

        for(var i = parts.length - 1; i >= 0; i--){
            if (parts[i].includes("web") || parts[i].includes("http:") || parts[i].includes("https:") || parts[i].includes("localhost:5001") || parts[i].includes("dauto.nl") || parts[i].includes("dauto.be") || parts[i].includes("deautogids.nl")) {
                parts.splice(i, 1);
            }
            // remove empty space from breadcrumb
            if (parts[i] === "") {
                parts.splice(i, 1);
            }
            // remove numbers from breadcrumb
            if (parts[i]) {
                if (isNumber(parts[i])) {
                    parts.splice(i, 1);
                }
            }
        }

        if (isNaN(parseInt(place, 10))) {

            return (
            <div id='translator' ><ul className='breadcrumb'>
                {parts.map(crumb)}
                /
                <li><Link to={place}>{decodeURI(place)}</Link></li>
            </ul></div>)
        }
        else {
            // removes the number in the breadcrumb
            const place = parts[parts.length-1]
            parts.splice(parts.lastIndexOf(place))
            return (
            <div id='translator' ><ul className='breadcrumb'>
                {parts.map(crumb)}
                /
                <li><Link to={place}>{decodeURI(place)}</Link></li>
            </ul></div>)
        
        }
    }
} 
    
/>)

const crumb = (part, partIndex, parts) => {
    const path = ['', ...parts.slice(0, partIndex+1)].join("/");
    if (path !== '/pagina' && path !== '/merk' && path !== '/zoek')
        return (<li key={path}><Link to={path} >{decodeURI(part)}</Link></li>)
    else
        return (<li key={path}><Link to='/' >Home</Link></li>)
}

export default Breadcrumbs;