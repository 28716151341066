import React, { Component } from 'react';
import { Form, Input, TextArea, Button, Message, Segment } from 'semantic-ui-react'
import Recaptcha from '../../dumb/Recaptcha'
var qs = require('qs');

const sitekey = '6LfmlJgUAAAAAH8I3dcGsofmmttbMBN4LUhSVVmJ'

class ContactContainer extends Component { 
    state = { verified: false, error: false, success: false }

    verifyCallback = (response) => {
        this.setState({verified: true})
    };
    

    componentDidMount = () => {
    }

    handleChange = (e, { id, value }) => this.setState({ [id]: value })

    sendMail = () => {
        const {firstName, lastName, from, subject, text} = this.state
        const email = {
            from: {
                name: firstName + ' ' + lastName,
                email: from,
              },
            subject: subject,
            text: text
        }

        fetch('/api/mail/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
        
            body: qs.stringify({email: email})
          })
          .then((response) => {
              if (response.status === 201)
              {
                this.setState({success: true})
              }
        })
          .catch(err => {
              this.setState({error: true})
          })
      
    }


    render = () => {
        const { verified, error, success } = this.state
        return(
            <Segment>
                {success ? <div>Bedankt voor uw e-mail, wij nemen zo spoedig mogelijk contact met je op!</div> : 
                <Form error={error}>
                    <Message
                        error
                        header='E-mail niet verzonden'
                        content='Je e-mail bericht is helaas niet verzonden door een systeemfout'
                    />
                    <Form.Group widths='equal'>
                    <Form.Field onChange={this.handleChange} control={Input} id='firstName' label='Voornaam' placeholder='Voornaam' />
                    <Form.Field onChange={this.handleChange} control={Input} id='lastName' label='Achternaam' placeholder='Achternaam' />
                    </Form.Group>
                    <Form.Group inline>
                        <Form.Field onChange={this.handleChange} control={Input} id='from' label='E-mail adres' placeholder='E-mail' />
                    </Form.Group>

                    <Form.Field onChange={this.handleChange} control={Input} id='subject' label='Onderwep' placeholder='Onderwerp' />
                    <Form.Field onChange={this.handleChange} control={TextArea} id='text' label='Bericht' />
                    <Recaptcha
                        verifyCallback={this.verifyCallback}
                        sitekey={sitekey}
                        render="explicit"
                        theme="light"
                        hl='nl'
                    />
                    <Form.Field control={Button} primary disabled={!verified} onClick={() => this.sendMail()}>Versturen</Form.Field>
                </Form>
                }
            </Segment>

        )
    }
}

export default ContactContainer