import React, { Component } from 'react';
import { Categorie } from '../../dumb/categorie'
import { Accordion } from 'semantic-ui-react';
class CategorieContainer extends Component { 
    state = { last10: [], categorieen: [], name: '', activeIndex: 0 }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { categorieNaam, categorieNummer } = newProps
        if (categorieNaam) {
            this.getCategorieen(categorieNaam)
        }
        else
        {
            this.getCategorie(categorieNummer);
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
      }

    getCategorie = (number) => {
        fetch('/api/categorie/' + number)
        .then(response => response.json())
        .then(data => this.setState({ categorie: data, name: data[0].sitedeel }))
        .catch(err => {
          
        })
    }

    getCategorieen = (categorieNaam) => {
        fetch('/api/categorie')
        .then(response => response.json())
        .then(data => this.setState({ name: categorieNaam, categorieen: data }, () => {
            data.map((categorie) => {
                if (categorie.sitedeel === categorieNaam) {
                    
                    return this.getCategorie(categorie.id)
                }
                else {
                    return null
                }
            })
        }))
        .catch(err => {
          
        })
      }

    componentDidMount = () => {
        const { categorieNaam, categorieNummer } = this.props
        if (categorieNaam) {
            this.getCategorieen(categorieNaam)
        }
        else
        {
            this.getCategorie(categorieNummer);
        }
    }

    render = () => {
        const { categorie, activeIndex } = this.state

        return (
            <Accordion styled fluid>

                {categorie ? 
                    categorie.map((cat, index) => {
                        return(<Categorie key={index} index={index} active={activeIndex === index} Cat={cat} onClick={(e, data) => this.handleClick(e, data)}/>)
                    })
                    : 
                    <div />
                }

            </Accordion>
        )

    }
}

export default CategorieContainer