import React, { Component } from 'react';
import { Icon, Label, Message } from 'semantic-ui-react'

var qs = require('qs');
export class NoMatch extends Component {
  
  componentDidMount = () => {
    const url = window.location.href
    const error = JSON.stringify(window.location)

    fetch('/api/error', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: qs.stringify({
        url: url,
        error: error
      })
    })
    .catch(err => {
      
    })
}

  render = () => {
    return (
      <div>
        <h3>
          
        </h3>


        <Message error>
        <Message.Header>No match for <code>{window.location.pathname}</code></Message.Header>
        <Message.List items={['This has been automaticly reported to the site administrators']}></Message.List>
        </Message>

        <Label as='a' color='orange' href='/'><Icon name='globe' />Click here to go back to the main page</Label>
      </div>
    );
  }
}

export default NoMatch