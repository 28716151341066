
import React from 'react'
import { Accordion, Icon, Header, List, Segment } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { rawMarkup } from '../../../helpers'

export const CategorieList = ({categorieen}) => (
    <Segment id='vav' raised>
        <Header>Menu</Header>
        <List>
            {categorieen.length > 0 ? categorieen.map((categorie,index) => {
                return (<List.Item key={index} ><Link to={`/pagina/${categorie.sitedeel}`} >{categorie.sitedeel}</Link></List.Item>)
            }):
            <span><Icon loading name='spinner' />loading ...</span>
            }
        </List>
    </Segment>
);

export const Categorie = ({active, Cat, onClick, index}) => (
    <>
        <Accordion.Title active={active} index={index} onClick={onClick} >
            <Icon name='dropdown' />
            {Cat.titel}
        </Accordion.Title>
        <Accordion.Content active={active}>
          <p dangerouslySetInnerHTML={rawMarkup(Cat.inhoud)}/>
        </Accordion.Content>
    </>
);