export const merk = {
    "id": 0,
    "merk": "",
    "land": "",
    "van": '0',
    "tot": '0',
    "geschiedenis": "",
    "afbeelding": "",
    "plaats": "",
    "personen": "",
    "personenauto": false,
    "vrachtauto": false,
    "autobus": false,
    "bronnen": "",
    "brontekst": "",
    "tf_url": ""    
  }