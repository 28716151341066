import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
var qs = require('qs');

class AddCommentContainer extends Component {
    state = { model: {}, success: false, error: false }

    componentDidMount = () => {
        const { model } = this.props
        if (model) {
            this.setState({model: model});
        }
    }

    sendComment = () => {
        const { model } = this.state
        const { modelId } = this.props
        fetch('/api/commentaar/' + modelId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
        
            body: JSON.stringify(model)
          })
          .then((response) => {
            if (response.status === 201)
            {
              this.setState({done: true, error: false }, () => {
                  this.sendMail();
              })
            }
            else {
                this.setState({done: false, error: true})
            }
        })
        .catch(err => {
            this.setState({done: false, error: true})
        })
    }

    sendMail = () => {
        const { model } = this.state
        const { modelId } = this.props

        const email = {
            from: {
                name: model.Naam,
                email: model.Email,
              },
            subject: 'Nieuw commentaar toegevoegd bij een merk',
            text: `Nieuw commentaar toegevoegd bij https://dauto.nl/merk/${modelId} \r\n` + model.Comment
        }

        fetch('/api/mail/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
        
            body: qs.stringify({email: email})
          })
          .then((response) => {
              if (response.status === 201)
              {
                this.setState({success: true})
              }
        })
          .catch(err => {
              this.setState({error: true})
          })
      
    }

    changeModel = (e, {id, value}) => {
        e.preventDefault();

        let { model } = this.state
        model[id] = value
        this.setState({model})
    }

    render = () => {
        const { success, error } = this.state
        if (success) {
          return  (
            <Message
                positive
                header='Commentaar geplaatst'
                content='Bedankt voor uw commentaar!'
            />
          )
        }
        if (error) {
          return (
            <Message
                error
                header='Commentaar niet geplaatst'
                content='Helaas kon je commentaar niet geplaatst worden door een systeemfout'
            />
          )
        }
        if (!success) {
          return (
              <Form reply>
                <Form.Input onChange={this.changeModel} id="Naam" label='Naam' />
                <Form.Input onChange={this.changeModel} id="Email" label='Email' placeholder='naam@email.com' />
                <Form.TextArea onChange={this.changeModel} id="Comment" />
                <Button onClick={() => this.sendComment()} labelPosition='left' content='Reply' icon='edit' primary />
              </Form>
          )
        }
    }

}
export default AddCommentContainer