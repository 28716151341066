import React, { Component } from 'react';
import Last10 from './components/dumb/last10';
import { CategorieList } from './components/dumb/categorie';

class App extends Component {
  state = { last10: [], categorieen: [] }
  
  getTop10 = () => {
    fetch('/api/merken/last10')
    .then(response => response.json())
    .then(data => this.setState({ last10: data }))
    .catch(err => {
      
    })
  }

  getCategorieen = () => {
    fetch('/api/categorie')
    .then(response => response.json())
    .then(data => this.setState({ categorieen: data }))
    .catch(err => {
      
    })
  }

  componentDidMount(){
    this.getTop10();
    this.getCategorieen();
  }

  render() {
    const { last10, categorieen } = this.state
    return (
      <>
        <Last10 last10={last10} />
        <CategorieList categorieen={categorieen} />
      </>
    );
  }
}

export default App;
