import React, { Component } from 'react';
import { Pagina } from '../../dumb/pagina'
import Statistics from '../../dumb/statistics';
import { Segment } from 'semantic-ui-react';

class PaginaContainer extends Component {
    state = { pagina: undefined, totals: [] }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { paginaNummer } = newProps
        this.getPagina(paginaNummer);
        if (paginaNummer === undefined)
        {
            this.clearState()
            this.getTotals()
        }
    }
    
    clearState = () => {
        this.setState({pagina: undefined, totals: []})
    }

    getTotals = () => {
        fetch('/api/merken/total')
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else {
                return null
            }
            
        })
        .then(data => {
            this.setState({totals: data})
        })
        .catch(err => {
            console.log(err)
        })
    }

    getPagina = (number) => {
        if (number !== undefined)
        {
            fetch('/api/pagina/' + number)
            .then(response => response.json())
            .then(data => {
                this.setState({ pagina: data}, () => {
                })
            })    
            .catch(err => {
              console.log(err)
            })    
        }
    }
        
    componentDidMount = () => {
        const { paginaNummer } = this.props
        if (paginaNummer) {
            this.getPagina(paginaNummer);
        }
        if (!paginaNummer) {
            this.getTotals();
        }
    }

    render = () => {
        const { pagina, totals } = this.state
        if (pagina) {
            return (
                <Pagina clearState={() => this.clearState()} page={pagina} />
            )
        }
        else
        {
            return (
            <Segment>
            <div style={{background: "url('/gids/achtergrond.jpg') no-repeat top right", marginRight:"10px"}}>
            <div style={{width:'240px', height:'62px',float:'right'}}></div>
            <h1 style={{width:'290px', height:'64px'}}>Welkom bij dAuto.nl</h1>

            <div style={{width:'170px', height:'36px', float:'right'}}></div>
            Op deze site kunt u het volgende vinden:<br /> <br /> <br />
                { totals ? <Statistics stats={totals} /> : null }  <br /> <br /> 
            <script type="text/javascript">
              google_ad_client = "pub-7036031027093279";
              
              google_ad_slot = "2950416034";
              google_ad_width = 468;
              google_ad_height = 60;

            </script>
            
            
            
        
          </div></Segment>)

        }

    }
}

export default PaginaContainer