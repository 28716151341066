import React, { Component } from 'react';
import { Header, List, Segment } from 'semantic-ui-react'

class AdminApp extends Component {
 
  render() {
    return (
      <Segment id='nav' raised>
          <Header>Admin menu</Header>
          <List>
              <List.Item as='a' href={'/beheer/newBrand'}>Merk toevoegen</List.Item>
              <List.Item as='a' href={'/beheer/editBrand'}>Merk bewerken</List.Item>
          </List>
      </Segment>

    );
  }
}

export default AdminApp;
