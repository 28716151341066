import React from 'react'

import VirtualList from 'react-virtual-list';
import { Icon, Label, List } from 'semantic-ui-react'
import { LandLabel } from '../landLabel';

const MyList = ({
    virtual,
    itemHeight,
    poss
  }) => (
    <List selection verticalAlign='middle' style={virtual.style}>
      {virtual.items.map(item => (
        <List.Item as='a' href={'/merk/' + item.label + '/' + item.key} key={`item_${item.key}`} style={{height: itemHeight}}>
          <List.Content>
            <List.Header >{item.label} 
              { item.afbeelding !== '' ? <>&nbsp;<Icon name='camera' /></> : null }
              {poss ? 
                  <Label style={{float: 'right'}} as='a' href={'/beheer/edit/' + item.key} image={true} circular>
                    <Icon name='edit' />
                  </Label>
                  :
                  null
              }
            </List.Header>
            <List.Description>
                <span>land(en): {item.countries.map((country, index) => {
                  return(<LandLabel key={index} Land={country} />)}
                )}</span>
                <span>{' '}van: <b>{item.van}</b></span>
                <span>{' '}tot: <b>{item.tot}</b></span>
            </List.Description>
        </List.Content>
        </List.Item>
      ))}
    </List>
  );

  export const MyVirtualList = VirtualList()(MyList);