import React from 'react'
import { Icon, Statistic } from 'semantic-ui-react'


const colorArray = [
    'red',
    'orange',
    'yellow',
    'olive', 
    'green', 
    'teal', 
    'blue', 
    'violet', 
    'purple', 
    'pink', 
    'brown', 
    'grey', 
    'black'
];
const randomNumber = () => {
    const random = Math.floor(Math.random()*colorArray.length);

    return colorArray[random]
}

const Statistics = ({stats}) => (
    <Statistic.Group size='small'>
        {stats.map(stat => {
            return (
                stat.link ? 
                    <Statistic as='a' href={stat.link} title={`Ga naar ${stat.label}`} color={randomNumber()} key={stat.key}>
                        <Statistic.Value>
                            <Icon name={stat.key} size='tiny' />&nbsp;&nbsp;{stat.value}
                        </Statistic.Value>
                        <Statistic.Label>{stat.label}</Statistic.Label>
                    </Statistic> 
                : 
                    <Statistic color={randomNumber()} key={stat.key}>
                        <Statistic.Value>
                            <Icon name={stat.key} size='tiny' />&nbsp;&nbsp;{stat.value}
                        </Statistic.Value>
                        <Statistic.Label>{stat.label}</Statistic.Label>
                    </Statistic>
            )
        })}
    </Statistic.Group>
)

export default Statistics
