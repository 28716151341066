import React from 'react'
import { Label, Icon, Segment, Table } from 'semantic-ui-react'
import { rawMarkup } from '../../../helpers'
import Comments from '../comments'
import ImageGroup from '../imageGroup';
import { LandLabel } from '../landLabel';
import { comment } from '../../../models/Comment'

export const Merk = (props) => {
    const createIcons = () => {
        let icon = []
        if (props.Car.autobus) 
            icon.push(<Icon key='bus' name='bus' />)
        if (props.Car.vrachtauto) 
            icon.push(<Icon key='truck' name='truck' />)
        if (props.Car.personenauto) 
            icon.push(<Icon key='car' name='car' />)

        return icon || null
    }


    return(<Segment.Group style={{width: '100%'}} >
            <Table compact>
                <Table.Body>
                    <Table.Row >
                        <Table.Cell width='8' colSpan='4'>{createIcons()} <strong>{props.Car.merk}</strong> {' '}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='4' colSpan='2'>Land</Table.Cell>
                        <Table.Cell width='4' colSpan='2'>{props.Car.countries.map((country, index) => {
                                    return(<LandLabel Land={country} key={index} />)}
                                )}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='4' colSpan='2'>Plaats</Table.Cell>
                        <Table.Cell width='4' colSpan='2'>{props.Car.cities.map((city, index) => {
                                    return(<div key={index}>{city}</div>)}
                                )}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='2'>Van</Table.Cell>
                        <Table.Cell width='2'><strong>{props.Car.van}</strong></Table.Cell>
                        <Table.Cell width='2'>Tot</Table.Cell>
                        <Table.Cell width='2'><strong>{props.Car.tot}</strong></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='4' colSpan='2'>Belangrijke personen</Table.Cell>
                        <Table.Cell width='4' colSpan='2'>{props.Car.persons.map((person, index) => {
                                    return(<div key={index}>{person}</div>)}
                                )}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        <Segment padded>
            <p dangerouslySetInnerHTML={rawMarkup(props.Car.geschiedenis2, 'merk')} />
            {props.Car.afbeelding ? <ImageGroup handleShowImage={(src) => props.handleshowimage(src)} carid={props.Car.ID} images={props.Car.afbeelding.split(' ')} /> : null }
            {props.Car.tf_url ? <><strong>Bronnen:</strong><br /><Label basic as='a' href={props.Car.tf_url}>{props.Car.tf_url}</Label><br /></> : null}
            {props.Car.bronnen ? <>{props.Car.tf_url ? <></> : <strong>Bronnen:<br /></strong>}<Label basic as='a' href={props.Car.bronnen}>{props.Car.brontekst}</Label><br /></> : null}

            <Comments modelId={props.Car.ID} model={comment} comments={props.Car.comments} />
       </Segment>
    </Segment.Group>);  
}