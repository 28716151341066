import React from 'react'
import { Segment } from 'semantic-ui-react'
import { rawMarkup } from '../../../helpers'

export const Pagina = ({ page }) => {
    const currentPage = page[0]
    if (currentPage) {
        return(<Segment raised>
            <p dangerouslySetInnerHTML={rawMarkup(currentPage.inhoud, 'categorie')}></p>
        </Segment>)
    }
    else {
        return (<Segment raised loading />)
    }
}