import React, {Component} from 'react';
import PropTypes from 'prop-types';


// Wait for the recaptcha object to become available
// The promise will resolve as soon as needed, keep resolving with the
// cached object.
const waitForRecaptcha = new Promise(resolve=> {
    let interval = setInterval(()=> {
        if (typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined') {
            clearInterval(interval);
            resolve(window.grecaptcha);
        }
    }, 1000);
});


export default class Recaptcha extends Component {

    constructor(props) {
        super(props);
        this.state = {widget: null};
        this._containerRef = null;
    }

    componentDidMount() {
        this._renderGrecaptcha();
    }

    _renderGrecaptcha() {
        const {sitekey, theme, type, size, tabindex, hl, badge,
               verifyCallback, expiredCallback, onloadCallback} = this.props;

        waitForRecaptcha.then(grecaptcha=> {
            const widget = grecaptcha.render(this._containerRef, {
                sitekey, theme, type, size, tabindex, hl, badge,
                callback: verifyCallback,
                'expired-callback': expiredCallback,
            });
            this.setState({widget});
        });

        if (onloadCallback) {
            onloadCallback();
        }
    }

    render() {
        return <div ref={el=> this._containerRef = el} />;
    }
}


Recaptcha.propTypes = {
    onloadCallback: PropTypes.func,
    verifyCallback: PropTypes.func,
    expiredCallback: PropTypes.func,
    sitekey: PropTypes.string.isRequired,
    theme: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    tabindex: PropTypes.string,
    hl: PropTypes.string,
    badge: PropTypes.string,
};


Recaptcha.defaultProps = {
    onloadCallback: undefined,
    verifyCallback: undefined,
    expiredCallback: undefined,
    theme: 'light',
    type: 'image',
    size: 'normal',
    tabindex: '0',
    hl: 'en',
    badge: 'bottomright',
};