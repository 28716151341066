
import React from 'react'
import { Header, List, Icon, Segment } from 'semantic-ui-react'

const Last10 = ({last10}) => (
    <Segment id='nav' raised>
        <Header>Laatste update</Header>
        <List>
            {last10.length > 0 ? last10.map((merk,index) => {
                return (<List.Item as='a' href={'/merk/' + merk.id + '/' + merk.merk} key={index} >{merk.merk}</List.Item>)
            }):
            <span><Icon loading name='spinner' />loading ...</span> }
        </List>
    </Segment>
);

export default Last10