import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'

import "isomorphic-fetch"

import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
  } from "react-router-dom";

import App from './App';
import AdminApp from './AdminApp';

import { NoMatch } from './components/dumb/noMatch'

import Main from './containers/Main';
import Breadcrumbs from './components/dumb/breadCrumb';
import SearchContainer from './components/smart/searchContainer';

// import * as serviceWorker from './serviceWorker';
import 'core-js';

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);

export class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const routes = [
            {
                path: "/",
                exact: true,
                main: (props) => <><div id='content'><Main comp='pagina' {...props} /></div><div id='sider'><App {...props} /></div></>,
            },
            {
                path: "*/zoek/:zoekvorm/:land",
                exact: true,
                main: (props) => <><div id='content'><Main comp='search' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/zoek/:term",
                exact: true,
                main: (props) => <><div id='content'><Main comp='search' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/zoek",
                exact: true,
                main: (props) => <><div id='content'><Main comp='search' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/landen",
                exact: true,
                main: (props) => <><div id='content'><Main comp='landen' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/contact",
                exact: true,
                main: (props) => <><div id='content'><Main comp='contact' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "/translate_c",
                exact: true,
                main: (props) => <><div id='content'><Main comp='pagina' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/pagina/categorie/:id",
                exact: true,
                main: (props) => <><div id='content'><Main comp='pagina' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/pagina/:id",
                exact: true,
                main: (props) => <><div id='content'><Main comp='categorie' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/merk/:id/:nummer",
                exact: true,
                main: (props) => <><div id='content'><Main comp='merk' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "*/merk/:id",
                exact: true,
                main: (props) => <><div id='content'><Main comp='merk' {...props} /></div><div id='sider'><App {...props} /></div></>
            },
            {
                path: "/beheer/:beheerActie/:id",
                exact: true,
                main: (props) => {
                    if (window.location.hostname.includes('deautoapi') || window.location.hostname.includes('localhost')) {
                        return(<><div id='content'><Main comp='admin' {...props} /></div><div id='sider'><AdminApp {...props} /></div></>)
                    }
                    else {
                        return (<NoMatch />)
                    }
                }
            },
            {
                path: "/beheer/:beheerActie",
                exact: true,
                main: (props) => {
                    if (window.location.hostname.includes('deautoapi') || window.location.hostname.includes('localhost')) {
                        return(<><div id='content'><Main comp='admin' {...props} /></div><div id='sider'><AdminApp {...props} /></div></>)
                    }
                    else {
                        return (<NoMatch />)
                    }
                }
            },
            {
                path: "/beheer",
                exact: true,
                main: (props) =>  {
                    if (window.location.hostname.includes('deautoapi') || window.location.hostname.includes('localhost')) { 
                        return(<><div id='content'><Main comp='admin' {...props} /></div><div id='sider'><AdminApp {...props} /></div></>)
                    }
                    else {
                        return (<NoMatch />)
                    } 
                }
            }

        ]
        return (
            <>            
                <div id='header' style={{paddingTop: '0px', paddingBottom: '0px' }}>
                    <SearchContainer />
                </div>
                
                <BrowserRouter>
                    <Switch>
                        <Redirect preserveQueryString={false} from="/begin/default.asp" to={`/`}/>
                        <Redirect preserveQueryString={false} from="/begin" to={`/`}/>

                        <Redirect preserveQueryString={false} from="/begin/pagina.asp" to={`/pagina/${parsed['page']}`}/>
                        <Redirect preserveQueryString={false} from="/begin/categorie.asp" to={`/pagina/categorie/${parsed['cat']}`}/>
                        <Redirect preserveQueryString={false} from="/pagina/zoeken" to={`/zoek`}/>
                        <Redirect preserveQueryString={false} from="/auto/zoeken.asp" to={`/zoek/${parsed['zoekvorm']}/${parsed['zoekterm']}`} />
                        <Redirect preserveQueryString={false} from="/auto/merk.asp" to={`/merk/${parsed['desc']}/${parsed['merk']}`} />

                        <Redirect preserveQueryString={false} from="/contact.asp" to={`/contact`} />

                        <Redirect preserveQueryString={false} from="/auto" to={`/`}/>

                        <Redirect preserveQueryString={false} from="/search" to={`/zoek`} />

                        <Redirect preserveQueryString={false} from="/merk/:nummer/:naam/no_redirect" to={'/merk/:naam/:nummer'} />
                        <Redirect preserveQueryString={false} from="/merk/:nummer/:naam/reacties" to={'/merk/:naam/:nummer'} />
                        <Redirect preserveQueryString={false} from="/merk/:nummer/:naam/*" to={'/merk/:naam/:nummer'} />
                        
                        <Redirect preserveQueryString={false} from='/encarpedia' to={'/pagina/Encarpedia'} />
                    </Switch>

                    <Breadcrumbs />

                    {routes.map((route, index) => {
                        return (
                            
                            <Route key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.main}
                            />
                            
                        )
                    })}
                                
                </BrowserRouter>
               
            </>
            )
                {/* 
                */}
    }
}


ReactDOM.render(<Index />, document.getElementById('container'));
