import React, { Component } from 'react';
import NewForm from './newForm'
import SearchPageContainer from '../searchPageContainer'
import { merk } from '../../../models/Brand'
import EditForm from './editForm';

class AdminContainer extends Component { 
    state = { }

    componentDidMount = () => {

    }

    render = () => {
        const { match } = this.props
        if (match.params.beheerActie) {
            if (match.params.beheerActie === 'newBrand') {
                return (<><NewForm model={merk} /></>)
            }
            else if (match.params.beheerActie === 'edit') {
                return (<> <EditForm model={merk} id={match.params.id} {...this.props} /> </>)
            }
            else if (match.params.beheerActie === 'editBrand') {
                return (<> <SearchPageContainer poss={true} {...this.props}></SearchPageContainer> </>)
            }
        }
        
        return ( null
        )
    }
}

export default AdminContainer